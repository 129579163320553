import React, { useEffect } from 'react';
import { Input } from '@vkontakte/vkui';

const TextInput = ({ name, form, type = 'text', required = false, validator = null, className = '', placeholder = '' }) => {
    useEffect(() => {
        if (!form.state[name]) {
            form.createField(name, type, required, placeholder, validator);
        }
    }, []);

    return (
        <Input
            className={className}
            value={(form.state[name] && form.state[name].value) || ''}
            status={(form.state[name] && form.state[name].status) || 'default'}
            onChange={(e) => {
                const value = e.currentTarget.value;
                form.changeValue(name, value);
            }}
        />
    );
};

export default TextInput;
