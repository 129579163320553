export let models = {};

export const addModel = (name, state, actions, reducer) => {
    models = {
        ...models,
        [name]: {
            state,
            actions,
            reducer,
            transport: [],
        }
    };
};

export const connectToModel = (name, handler) => {
    models = {
        ...models,
        [name]: {
            ...models[name],
            transport: [
                ...models[name].transport,
                handler
            ],
        }
    }
};

export const disconnectFromModel = (name, handler) => {
    models = {
        ...models,
        [name]: {
            ...models[name],
            transport: models[name].transport.filter((x) => x !== handler),
        }
    }
};

export const updateModelState = (name, state) => {
    models = {
        ...models,
        [name]: {
            ...models[name],
            state: {
                ...models[name].state,
                ...state,
            },
        }
    };
};

export const removeModel = (name) => {
    models = Object.keys(models).reduce((a, x) => {
        if (x !== name) {
            a[x] = models[x];
        }

        return a;
    }, {});
};
