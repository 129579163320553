const validateAppParams = (url = "", secret_key = "") => {
    const query_params = url.split("&").reduce((a, x) => {
        const data = x.split("=");
        a[data[0]] = data[1];
        return a;
    }, {});

    const sign_params = {};
    Object.keys(query_params).sort()
        .forEach((key) => {
            if(!key.startsWith("vk_")) return;
            sign_params[key] = query_params[key];
        });

    const sign_str = Object.keys(sign_params).reduce((a, x) => {
        a.push(x + "=" + sign_params[x]);
        return a;
    }, []).join("&");

    let sign = require("crypto").createHmac("sha256", secret_key).update(sign_str);
    sign = sign.digest("binary");
    sign = require("buffer").Buffer.from(sign, "binary").toString("base64");
    sign = sign.split("+").join("-");
    sign = sign.split("/").join("_");
    sign = sign.replace(/=+$/, '');
    // сравниваем подпись с оригинальной. если все окей, то возвращаем id пользователя, если нет - null
    return sign === query_params["sign"] ? query_params["vk_user_id"] : null;
};

export default validateAppParams;
