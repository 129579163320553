import { useReducer, useEffect } from 'react';
import { models, updateModelState, connectToModel, disconnectFromModel } from './state';

const useModel = (name, debug) => {
    // get model
    if (!models[name]) {
        console.log(`[Store]: Model "${name}" was not registered. Deny.`);
        return [ null, null ];
    }

    const model = models[name];

    const [ state, dispatch ] = useReducer(model.reducer, model.state);

    // make actions
    const connectedActions = Object.keys(model.actions).reduce((a, x) => {
        a[x] = (...args) => {
            const action = {
                type: x,
                ...model.actions[x](...args),
            };

            dispatch(action);
            models[name].transport.forEach((handler) => {
                if (handler !== dispatch) {
                    handler(action);
                }
            });
        };

        return a;
    }, {});

    useEffect(() => {
        updateModelState(name, state);
        if (debug) {
            console.log(`[Store][${name}]: ${JSON.stringify(state)}`);
        }
    }, [ state ]);

    useEffect(() => {
        connectToModel(name, dispatch);

        return () => {
            disconnectFromModel(name, dispatch);
        };
    }, []);

    return [
        state,
        connectedActions,
    ];
};

export default useModel;
