import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import bridge from '@vkontakte/vk-bridge';
import { View } from '@vkontakte/vkui';

const Page = ({ id, initialPanel, router, children, debug, ...rest }) => {
    const [ activePanel, setActivePanel ] = useState((router.state[id] && router.state[id].activePanel) || initialPanel);
    const [ history, setHistory ] = useState((router.state[id] && router.state[id].history) || [ initialPanel ]);

    const onUpdate = ({ activePanel, history }) => {
        setActivePanel(activePanel);
        setHistory(history);
    };

    useEffect(() => {
        if (debug) {
            console.log(activePanel, history, initialPanel);
        }
    }, [ activePanel, history, initialPanel, debug ]);

    useEffect(() => {
        router.getPageState(id, initialPanel, onUpdate, ({ activePanel, history }) => {
            setActivePanel(activePanel);
            setHistory(history);
            if (history.length > 1) {
                bridge.send('VKWebAppEnableSwipeBack', {});
            } else {
                bridge.send('VKWebAppDisableSwipeBack', {});
            }
        });
    }, []);

    return (
        <View
            id={id}
            onSwipeBack={router.goBack}
            activePanel={activePanel}
            history={history}
            {...rest}
        >
            {React.Children.map(children, (child) => React.cloneElement(
                child,
                {
                    ...child.props,
                    router,
                }
            ))}
        </View>
    );
};

Page.propTypes = {
    id: PropTypes.string.isRequired,
    initialPanel: PropTypes.string.isRequired,
    router: PropTypes.any,
    debug: PropTypes.bool,
    children: PropTypes.any.isRequired,
};

Page.default = {
    debug: false,
};

export default Page;

